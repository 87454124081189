<template>
  <div class="documentation-wrapper">
    <div class="container">
      <h1 class="head-text">Документация</h1>

      <div>
        <h2>Руководство администратора</h2>
        <div class="content">
          <a
            href="/files/documents/Пользовательская_документация_администратор.docx"
            target="_blank"
            >Руководство пользователя для администратора</a
          >
        </div>
      </div>
      <div>
        <h2>Руководство пользователя</h2>
        <div class="content">
          <a
            href="/files/documents/Пользовательская_документация_пользователь.pdf"
            target="_blank"
            >Пользовательская документация</a
          >
        </div>
      </div>
      <div>
        <h2>Описание функциональных характеристик</h2>
        <div class="content">
          <a
            href="/files/documents/Документация,_содержащая_описание_функциональных_характеристик_экземпляра.pdf"
            target="_blank"
            >Функциональные характеристики</a
          >
        </div>
      </div>
      <div>
        <h2>Процессы жизненного цикла</h2>
        <div class="content">
          <a
            href="/files/documents/Описание_процессов_обеспечивающих_поддержание_жизненного_цикла_ПО.docx"
            target="_blank"
            >Описание процессов, обеспечивающих поддержание жизненного цикла
            ПО</a
          >
        </div>
      </div>
      <div class="price_wrapper">
        <div class="price_header">
          <div>Стоимость решения</div>
          <div class="price">950 000&#8381;</div>
        </div>
        <div class="price_block">
          <button class="btn btn-o-accent btn-sm" @click="openPriceModal = true">Заявка на консультацию</button>
        </div>
      </div>
    </div>
    <pop-up-form
      title="Обсудить проект. Оставьте заявку!"
      type="Default"
      v-if="openPriceModal"
      @closeModal="openPriceModal = false"
    />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import PopUpForm from "../../components/pop-up-form/PopUpForm.vue";
export default {
  components: { PopUpForm },
  name: "documentation",
  setup() {
    const openPriceModal = ref(false);

    return { openPriceModal };
  },
};
</script>

<style scoped lang="scss">
.documentation-wrapper {
  width: 100%;
  padding: 121px 25px 50px;
  font-size: 17px;
  line-height: 24px;
  color: #414352;

  @media (max-width: 767px) {
    padding: 25px 15px 5px;
  }
}

h2 {
  font-size: 24px;
  line-height: 1.2;
  margin: 10px 0 15px;
  position: relative;
  line-height: 150%;
  padding: 20px 15px 20px 0;
}

button {
  background-color: transparent;
  border: 1px solid #0cbf13;
  cursor: pointer;
  font-weight: 300;
  margin-left: 0;
}

a {
  text-decoration: underline;
  color: #000;
}
.container {
  position: relative;
  margin: 0 auto;
  padding-top: 25px;
  max-width: 1170px;

  @media (max-width: 767px) {
    padding: 35px 15px;
  }
}

.price_wrapper {
  width: 600px;
  padding: 50px 50px 0;
  display: flex;
  flex-direction: column;
  margin: auto;

  @media (max-width: 767px) {
    width: 350px;
    padding: 30px 0;
  }
}

.price_header {
  background-color: #0cbf13;
  color: #ffffff;
  font-size: 24px;
}

.price_block,
.price_header {
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  align-items: center;
}

.price {
  font-size: 45px;
  font-weight: bold;
}
</style>